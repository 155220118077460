import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import theme from "../theme/Theme";

export default ({ uuid }: { uuid: string }) => {
  return (
    <Dialog open={true} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle style={{ backgroundColor: theme.palette.primary.dark }}>
        <Typography variant="h3" align="center">
          新版簽名公告
        </Typography>
      </DialogTitle>
      <DialogContent>
        <h3>請點擊以下<a href={`https://cloud-new.orange3.com.tw/laborcontract/public/${uuid}`}>連結</a>到新簽名頁面進行簽名</h3>
      </DialogContent>
    </Dialog>
  );
};
