"use client";
import React, { useState } from "react";
import DropDown from "./DropDown";
import { Box, Button } from "@mui/material";
import DatePickerView from "./DatePickerView";
import Chart from "./Chart";
import Sidebar from "./Sidebar";
import useGetChartResult from "./hooks/useGetChartResult";

export default function FinancePage() {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const { data, isLoading, isError, getChartData } = useGetChartResult();
  async function handle_sendData() {
    await getChartData(startMonth, endMonth, selectedOptions);
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="100vh"
      padding="0 20px"
    >
      <Box display="flex" alignItems="center" mb={2}>
        <DropDown
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
        <DatePickerView
          label="開始月份"
          date={startMonth}
          setDate={setStartMonth}
        />
        <DatePickerView
          label="結束月份"
          start={startMonth}
          date={endMonth}
          setDate={setEndMonth}
        />
        {/* <MultilevelDropdownMenu
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        /> */}
        <Button onClick={handle_sendData} variant="contained">
          提交
        </Button>
      </Box>
      <Sidebar />
      <Box width="100%" minHeight="400px">
        {isLoading ? (
          <p>loading...</p>
        ) : (
          <Chart result={data} selected={selectedOptions} />
        )}
      </Box>
    </Box>
  );
}
