import { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  ListSubheader,
} from "@mui/material";
import useGetCompanyIndustry from "./hooks/useGetCompanyIndustry";

export default function MultiSelectDropDown({
  selectedOptions,
  setSelectedOptions,
}) {
  const { industryCategory } = useGetCompanyIndustry();
  const handleSelectChange = (event) => {
    console.log(event.target.value);
    const selectedValues = event.target.value;

    // 限制最多选择三个选项
    if (selectedValues.length <= 2) {
      setSelectedOptions(selectedValues);
    }
  };

  // const industryCategory = [
  //   "醫療器材研發、設計、製造",
  //   "原物料",
  //   "加工食品",
  //   "餐飲連鎖",
  //   "顧問諮詢",
  //   "物流倉儲服務",
  //   "交易撮(媒)合",
  //   "一般零售",
  //   "機電系統工程",
  //   "系統整合服務",
  //   "應用/系統軟體設計開發",
  //   "貨櫃運輸集散及倉儲",
  //   "金控業/銀行業/保險業",
  //   "證券業",
  //   "租賃業",
  //   "製造商",
  //   "貿易商、代理商、經銷商",
  //   "零售通路",
  //   "顧問服務",
  //   "教育產業",
  //   "廣告行銷",
  //   "環保概念",
  // ];

  return (
    <form style={{ marginRight: 10 }}>
      <FormControl>
        <InputLabel id="dropdown">產業選單</InputLabel>
        <Select
          labelId="dropdown"
          id="dropdownSelect"
          multiple
          value={selectedOptions}
          label="產業選單"
          onChange={handleSelectChange}
          renderValue={(selected) => selected.join(", ")} // 显示已选项
        >
          {industryCategory.map((item) => (
            // <div key={category.name}>
            //   {/* <ListSubheader>{category.name}</ListSubheader> */}
            //   {category.list.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedOptions.includes(item)} />
              {item}
            </MenuItem>
            //   ))}
            // </div>
          ))}
        </Select>
      </FormControl>
    </form>
  );
}
