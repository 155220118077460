import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import theme from "../theme/Theme";

const data = [
  {
    name: "1月",
    毛利率: 1500,
    費用率: 1400,
    淨利率: 1700,
    a毛利率: 5000,
    a費用率: 5400,
    a淨利率: 5200,
    b毛利率: 3500,
    b費用率: 3400,
    b淨利率: 3200,
  },
  {
    name: "2月",
    毛利率: 1600,
    費用率: 1300,
    淨利率: 1700,
    a毛利率: 5100,
    a費用率: 5300,
    a淨利率: 5300,
    b毛利率: 3600,
    b費用率: 3300,
    b淨利率: 3100,
  },
  {
    name: "3月",
    毛利率: 1700,
    費用率: 1200,
    淨利率: 1800,
    a毛利率: 5200,
    a費用率: 5200,
    a淨利率: 5400,
    b毛利率: 3700,
    b費用率: 3200,
    b淨利率: 3000,
  },
  {
    name: "4月",
    毛利率: 1500,
    費用率: 1400,
    淨利率: 1700,
    a毛利率: 5000,
    a費用率: 5400,
    a淨利率: 5200,
    b毛利率: 3500,
    b費用率: 3400,
    b淨利率: 3200,
  },
  {
    name: "5月",
    毛利率: 1700,
    費用率: 1300,
    淨利率: 1800,
    a毛利率: 5100,
    a費用率: 5300,
    a淨利率: 5300,
    b毛利率: 3600,
    b費用率: 3300,
    b淨利率: 3100,
  },
  {
    name: "6月",
    毛利率: 1600,
    費用率: 1200,
    淨利率: 1700,
    a毛利率: 5200,
    a費用率: 5200,
    a淨利率: 5400,
    b毛利率: 3700,
    b費用率: 3200,
    b淨利率: 3000,
  },
  {
    name: "7月",
    毛利率: 1500,
    費用率: 1400,
    淨利率: 1700,
    a毛利率: 5000,
    a費用率: 5400,
    a淨利率: 5200,
    b毛利率: 3500,
    b費用率: 3400,
    b淨利率: 3200,
  },
];

export default function Chart({ result, selected }) {
  const [hiddenLines, setHiddenLines] = useState({});

  const toggleLineVisibility = (dataKey) => {
    setHiddenLines((prevHiddenLines) => ({
      ...prevHiddenLines,
      [dataKey]: !prevHiddenLines[dataKey],
    }));
  };

  const colorSeries = ["#2bc7a7", "#7545ed", "#ffd89e"];
  const colorSeries2 = ["#2bc7a7", "#ba14a7", "#ff9900"];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90%",
        height: "400px",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={600}
          data={result}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend
            onClick={(e: any) => {
              const { dataKey } = e;
              toggleLineVisibility(dataKey);
            }}
          />
          <Line
            type="linear"
            dataKey={`費用率`}
            stroke={"#7ce000"}
            key={`費用率`}
            hide={hiddenLines["費用率"]}
          />
          <Line
            type="linear"
            dataKey={`淨利率`}
            stroke={"#0031a3"}
            key={`淨利率`}
            hide={hiddenLines["淨利率"]}
          />
          <Line
            type="linear"
            dataKey={`毛利率`}
            stroke={"#d64d1e"}
            key={`毛利率`}
            hide={hiddenLines["毛利率"]}
          />
          {selected.map((item, index) => (
            <React.Fragment key={item}>
              <Line
                type="linear"
                dataKey={`${item}費用率`}
                stroke={index === 0 ? colorSeries[0] : colorSeries2[0]}
                key={`${item}費用率`}
                hide={hiddenLines[`${item}費用率`]}
              />
              <Line
                type="linear"
                dataKey={`${item}淨利率`}
                stroke={index === 0 ? colorSeries[1] : colorSeries2[1]}
                key={`${item}淨利率`}
                hide={hiddenLines[`${item}淨利率`]}
              />
              <Line
                type="linear"
                dataKey={`${item}毛利率`}
                stroke={index === 0 ? colorSeries[2] : colorSeries2[2]}
                key={`${item}毛利率`}
                hide={hiddenLines[`${item}毛利率`]}
              />
            </React.Fragment>
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
