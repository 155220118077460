import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import styled from "@emotion/styled";

interface DatePickerViewProps {
  label: string;
  date: string;
  start?: string;
  setDate: (date: string) => void;
}

const StyledDatePicker = styled(DatePicker)`
  height: 50px;
  margin-right: 10px;
`;

const DatePickerView: React.FC<DatePickerViewProps> = ({
  label,
  date,
  start,
  setDate,
}) => {
  const handleStartMonthChange = (date: Date | null) => {
    const startDate = dayjs(date).format("YYYY/M");
    setDate(startDate);
  };
  const maxDate = new Date();
  return (
    <StyledDatePicker
      placeholderText={label}
      selected={date ? dayjs(date, "YYYY/M").toDate() : null}
      onChange={handleStartMonthChange}
      showMonthYearPicker
      dateFormat="yyyy/MM"
      maxDate={maxDate}
      minDate={start ? dayjs(start, "YYYY/M").toDate() : null}
    />
  );
};

export default DatePickerView;
