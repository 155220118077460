import axios from "axios";
import React, { useEffect, useState } from "react";

export default function useGetAnalyze() {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const companyId = localStorage.getItem("companyId");

  async function getAnalyzeInfo() {
    if (!companyId) return setData("");
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `https://new-backend.orange3.com.tw/getAnalyze?companyId=${companyId}`
      );
      setData(data.content);
      setIsError(null);
    } catch (err: any) {
      setIsError(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAnalyzeInfo();
  }, []);

  return { data, isLoading, isError };
}
