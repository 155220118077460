import axios from "axios";
import React, { useEffect, useState } from "react";

type Message = {
  comment: string;
  fromUserId: number;
  id: number;
  type: string;
  year: number;
  month: number;
};
export default function useGetComment(date) {
  const [data, setData] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const companyId = localStorage.getItem("companyId");
  const apiurl = "https://new-backend.orange3.com.tw";
  function parseMonth(monthString: string) {
    const year = parseInt(monthString.substring(0, 4));
    const month = parseInt(monthString.substring(5));
    return { year, month };
  }
  async function getComments() {
    try {
      setIsLoading(true);
      const { month, year } = parseMonth(date);
      const { data } = await axios.get(
        `${apiurl}/getComments?companyId=${companyId}&year=${year}&month=${month}`
      );
      setData(data);
      setIsError(null);
    } catch (err: any) {
      setIsError(err);
    } finally {
      setIsLoading(false);
    }
  }
  async function deleteComment(commentId: number) {
    try {
      setIsLoading(true);
      await axios.delete(`${apiurl}/comments/${commentId}`);
      const temp = [...data];
      const result = temp.filter((item) => item.id !== commentId);
      setData(result);
      setIsError(null);
    } catch (err: any) {
      setIsError(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function editComment(commentId: number, comment: string) {
    try {
      setIsLoading(true);
      await axios.put(`${apiurl}/comments/${commentId}`, {
        comment,
      });
      setIsError(null);
    } catch (err: any) {
      setIsError(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function writeComment(comment: string, userId: number | undefined) {
    try {
      setIsLoading(true);
      const { month, year } = parseMonth(date);
      const { data } = await axios.post(`${apiurl}/writeComment`, {
        comment,
        fromUserId: userId,
        companyId,
        year,
        month,
      });
      setData((prev) => [...prev, data.data]);
      setIsError(null);
    } catch (err: any) {
      setIsError(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getComments();
  }, [date]);

  return {
    data,
    isLoading,
    isError,
    getComments,
    editComment,
    deleteComment,
    writeComment,
  };
}
