import axios from "axios";
import React, { useState } from "react";

export default function useGetChartResult() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const apiurl = "https://new-backend.orange3.com.tw";
  const companyId = localStorage.getItem("companyId");

  async function getChartData(
    start: string,
    end: string,
    industryArray: string[]
  ) {
    try {
      setIsLoading(true);
      const { data } = await axios.post(`${apiurl}/getFinance`, {
        companyId,
        start,
        end,
        industryArray,
      });
      setData(data);
      setIsError(null);
    } catch (err: any) {
      setIsError(err);
    } finally {
      setIsLoading(false);
    }
  }

  return { data, isLoading, isError, getChartData };
}
