import axios from "axios";
import React, { useEffect, useState } from "react";

export default function useGetCompanyIndustry() {
  const [data, setData] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const companyId = localStorage.getItem("companyId");

  async function getCompanyIndustry() {
    if (!companyId) return setData([]);
    try {
      setIsLoading(true);
      const { data }: { data: any } = await axios.get(
        `https://new-backend.orange3.com.tw/getCompanyIndustry?companyId=${companyId}`
      );
      const { industry } = data;
      console.log(data);
      if (industry.includes(",")) {
        const array = industry.split(",");
        setData(array);
      } else {
        setData([industry]);
      }
      setIsError(null);
    } catch (err: any) {
      setIsError(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCompanyIndustry();
  }, []);

  return { industryCategory: data, isLoading, isError };
}
