import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import NavigateNewSysDialog from '../component/NavigateNewSysDialog';

const Page = forwardRef(({
  children,
  title = '',
  ...rest
}:any, ref) => {
  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
      {/* <NavigateNewSysDialog url={""}/> */}
    </div>
  );
});



export default Page;
