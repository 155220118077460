import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import useGetAnalyze from "../hooks/useGetAnalyze";

export default function Analyze() {
  const { data, isLoading, isError } = useGetAnalyze();

  if (isLoading) return <p>loading...</p>;
  if (isError) return <p>{String(isError)}</p>;

  return (
    <Box sx={{ width: "100%", height: "calc(100vh - 170px)" }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h4" sx={{ mr: 2 }}>
          分析概況
        </Typography>
      </Box>
      <div
        style={{
          height: "100%",
          border: "1px solid gray",
          borderRadius: "10px",
          overflowY: "auto",
          padding: "10px",
        }}
      >
        {data}
        {/* <Box>
          <Typography variant="subtitle1">單月狀況</Typography>
          <Box sx={{ my: 1 }}>
            <Typography variant="body1">1月</Typography>
            <Typography>test</Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle1">產業情形</Typography>
          <Typography>test</Typography>
        </Box> */}
      </div>
    </Box>
  );
}
