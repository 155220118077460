import {useParams} from "react-router";
import {Maybe, useGetPublicLaborContractQuery} from "../generated/graphql";
import {CircularProgress} from "@mui/material";
import LaborContractView from "../labor-contracts/LaborContractView";
import {useEffect, useState} from "react";
import {isEmpty} from "lodash";
import NavigateSignDialog from "../labor-contracts/NavigateSignDialog";


export default ()=>
{

    const params = useParams();
    const {data,loading,error} = useGetPublicLaborContractQuery({variables:{uuid:params.uuid as string}})
    const [editLaborContract, setEditLaborContract] = useState<Maybe<any>>();

    useEffect(() => {
        const laborContract = data?.publicLaborContract
        setEditLaborContract({
            ...laborContract,
        })
    }, [data])
    if(error)
    {
        return <div>
            <h1>
                404, failed to find anything for this link. The link may have expired or already completed it's process.
                If you believe this is wrong please send an email to:....
            </h1>
        </div>
    }
    if (loading  || isEmpty(editLaborContract)) {
        return <CircularProgress/>;
    }
    return (
        <div>
            <LaborContractView editLaborContract={editLaborContract} setEditLaborContract={setEditLaborContract} isGranted={false}/>
            <NavigateSignDialog uuid={params.uuid ?? ""}/>
        </div>)
}