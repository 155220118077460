import React, { useEffect, useState } from "react";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import Analyze from "./analyze";
import Comment from "./comment";

export default function SideBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { value: 0, label: "分析概況" },
    { value: 1, label: "單月分析" },
  ];

  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        right: 0,
        zIndex: 2,
        backgroundColor: "#ffffff",
        height: "100vh",
      }}
    >
      {/* Toggle Switch */}
      <div
        style={{
          width: "0.25rem",
          height: "100vh",
          backgroundColor: "#DE5B00",
          //   marginRight: "1.25rem",
        }}
      >
        {!isOpen ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "2.5rem",
              height: "10rem",
              color: "white",
              backgroundColor: "#DE5B00",
              borderRadius: "0.5rem 0 0 0.5rem",
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(true)}
          >
            <p style={{ writingMode: "vertical-lr", whiteSpace: "nowrap" }}>
              分析概況
            </p>
            <IoMdArrowDropleft size="24" />
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "1.5rem",
              height: "2.5rem",
              color: "white",
              backgroundColor: "#DE5B00",
              borderRadius: " 0 0.5rem 0.5rem 0",
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(false)}
          >
            <IoMdArrowDropright size="24" />
          </div>
        )}
      </div>

      {isOpen && (
        <div style={{ padding: "5px", width: "400px" }}>
          {/* Tabs */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "1rem 0 ",
            }}
          >
            {tabs.map((item) => (
              <div
                key={item.label}
                style={{
                  height: "fit-content",
                  padding: "0.25rem",
                  cursor: "pointer",
                  backgroundColor:
                    activeTab === item.value ? "#DE5B00" : "transparent",
                  color: activeTab === item.value ? "white" : "black",
                  borderRadius: "0.25rem",
                  marginRight: "0.25rem",
                }}
                onClick={() => setActiveTab(item.value)}
              >
                {item.label}
              </div>
            ))}
            {/* <Select
              labelId="dropdown"
              id="select"
              value={type}
              label="產業選單"
              onChange={(e) => setType(e.target.value)}
            >
              {industryCategory.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
          </div>
          {/* Main Content */}
          {activeTab === 0 ? <Analyze /> : <Comment />}
        </div>
      )}
    </div>
  );
}
