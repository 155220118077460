import React from "react";
// import GoogleLogin, { GoogleLoginResponse } from "react-google-login";
import NotificationPopup from "../common/NotificationPopup";
import { GoogleLogin } from '@react-oauth/google';

export default ({ onSuccess }: { onSuccess: (a: string) => void }) => {
    return (
        <GoogleLogin
            onSuccess={async (credentialResponse) => {
                console.log(1, credentialResponse.credential)
                const res = await fetch(
                    `${process.env.REACT_APP_BACKEND_URI}/auth/login`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            id_token: credentialResponse.credential,
                        }),
                    }
                );
                const json = await res.json();
                if (json.access_token) {
                    onSuccess(json.access_token);
                }
                else {
                    NotificationPopup.error(<div>
                        <p>Failed to login.</p>
                        <p><strong>Error:</strong> {json.error}</p>
                        <p><strong>Message:</strong> {json.message}</p>
                    </div>);
                }
            }}
            onError={() => {
                console.log('Login Failed');
            }}
        />
    );
};
