import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import theme from "../theme/Theme";

export default ({ url }: { url: string }) => {
  // 使用 useState 管理对话框的开关状态
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    // 关闭对话框
    setOpen(false);
  };

  const handleRedirect = () => {
    // 處理跳轉邏輯
    window.location.replace(`https://cloud-new.orange3.com.tw/login`);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle style={{ backgroundColor: theme.palette.primary.dark }}>
        <Typography variant="h3" align="center">
          勞報單系統轉移公告
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{margin: "30px auto"}}>
        親愛的用戶，您好！<br /><br />
        我們非常高興地通知您，新系統已於2024年8月19日上午10:00正式上線，舊系統現已全面停止使用。為了確保您能順利使用我們的服務，請前往新系統網站並重新設定您的密碼。<br /><br />
        新系統網址： <a href="https://cloud-new.orange3.com.tw/login">https://cloud-new.orange3.com.tw/login</a><br /><br />
        操作步驟如下：<br /><br />
        1.打開新系統的登入頁面。<br /><br />
        2.點擊「重設密碼」。<br /><br />
        3.輸入您登入舊系統的電子郵件地址。<br /><br />
        4.按照電子郵件中的指示完成密碼重設。<br /><br />
        完成以上步驟後，即可在新系統中體驗完整的新功能！<br /><br />
        如您對此次公告有任何疑問，請隨時聯繫我們的LINE官方客服（ID：@932coslc）。<br /><br />
        非常感謝您的理解與支持！


        </div>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            handleRedirect();
            handleClose();
          }}
        >
          到新系統網站
        </Button>
      </DialogContent>
    </Dialog>
  );
};
