import React, { useState } from "react";
import useGetComment from "../hooks/useGetComment";
import { AiFillDelete } from "react-icons/ai";
import { useUser } from "../../auth/Auth";
import { RoleEnum } from "../../generated/graphql";
import DatePickerView from "../DatePickerView";
import theme from "../../theme/Theme";

export default function Comment() {
  const user = useUser();
  const userRoles = user?.roles || [];
  const [date, setDate] = useState("2023/12");
  const { data, isLoading, isError, writeComment, deleteComment } =
    useGetComment(date);
  const [message, setMessage] = useState("");

  if (isLoading) return <p>loading...</p>;
  if (isError) return <p>{String(isError)}</p>;

  return (
    <div style={{ height: "80%" }}>
      <DatePickerView label="月份選擇" date={date} setDate={setDate} />
      {isLoading ? (
        <p>loading...</p>
      ) : (
        <>
          <div
            style={{
              height: "92%",
              border: "2px solid gray",
              borderRadius: "8px",
              padding: "8px",
              margin: "8px 0",
              overflowY: "auto",
            }}
          >
            {data.map((item, index) => (
              <div
                key={item.id}
                style={{
                  marginBottom: "1px",
                  alignItems: "center",
                }}
              >

                <div style={{display: "flex"}}>
                  {item.fromUserId === user?.id ? (
                    <AiFillDelete
                    onClick={() => deleteComment(item.id)}
                    color="red"
                    style={{ cursor: "pointer" }}
                    />
                    ) : null}
                      <p>{item.comment}</p>
                </div>
              </div>
            ))}
          </div>
          <div style={{ display: "flex", padding: "0 20px" }}>
            <input
              value={message}
              placeholder="留言..."
              style={{
                width: "100%",
                border: "1px solid",
                borderRadius: "6px",
                marginRight: "4px",
              }}
              onChange={(e) => setMessage(e.target.value)}
            />
            {userRoles.includes(RoleEnum.Admin) ? (
              <button
                style={{
                  color: "white",
                  whiteSpace: "nowrap",
                  backgroundColor: `${theme.palette.primary.main}`,
                  borderRadius: "6px",
                  padding: "4px",
                }}
                onClick={() => {
                  writeComment(message, user?.id);
                  setMessage("");
                }}
              >
                留言
              </button>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
