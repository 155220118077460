import React, { useEffect, useState } from "react";
import { Outlet, RouteObject } from "react-router";
import CompanyList from "./companies/CompanyPage";
import { makeStyles } from "@mui/styles";
import MenuBar from "./layout/MenuBar";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import UserPage from "./users/UserPage";
import ContractorPage from "./contractor/ContractorPage";
import LaborContractDetail from "./labor-contracts/LaborContractDetail";
import LaborContractSignPage from "./front-stage/LaborContractSignPage";
import LaborContractPage from "./labor-contracts/LaborContractPage";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  useQuery,
} from "@apollo/client";
import { getAccessToken, logout, setAccessToken, useUser } from "./auth/Auth";
import { Box, Grid } from "@mui/material";
import Login from "./auth/Login";
import { onError } from "@apollo/client/link/error";
import App from "./front-stage/App";
import ReportPage from "./companies/ReportPage";
import Logo from "./image/logo.png";
import theme from "./theme/Theme";
import EmployeePage from "./employee/EmployeePage";
import LeavePage from "./leave/LeavePage";
import { Company } from "./generated/graphql";
import SalaryPage from "./salary/SalaryPage";
import FinancePage from "./finance/financePage";
import { GoogleOAuthProvider } from '@react-oauth/google';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.background.default /**/,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 200,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

LicenseInfo.setLicenseKey(
  "e86494dcf29b37d97d97c79bdc08f337Tz01MTE1OSxFPTE2OTUyNjc3ODY2OTEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export const CompanyContext = React.createContext<Company | undefined>(
  undefined
);

const MainLayout = () => {
  const [company, setCompany] = useState();
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [accessToken, setStateAccessToken] = useState(getAccessToken());
  if (accessToken == null) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={"/big_logo.png"} style={{ width: 347, height: 264 }} />
        <img src={"/slogan.png"} style={{ width: 416, height: 185 }} />
        <Grid display={"flex"}>
          <img src={Logo} style={{ width: 50, height: 50, marginRight: 20 }} />
          <GoogleOAuthProvider clientId="560885212787-o88nipfh7p163578bo59355af2o16a6m.apps.googleusercontent.com">
            <Login
              onSuccess={(accessToken) => {
                setAccessToken(accessToken);
                setStateAccessToken(accessToken);
              }}
            />
          </GoogleOAuthProvider>
        </Grid>
      </div>
    );
  }
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === "Unauthorized") {
          logout();
          setStateAccessToken(null);
        }
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
        );
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  const client = new ApolloClient({
    cache: new InMemoryCache({ addTypename: false }),
    link: ApolloLink.from([
      errorLink,
      new HttpLink({
        uri: `${process.env.REACT_APP_BACKEND_URI}/graphql`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    ]),
  });

  return (
    <ApolloProvider client={client}>
      <CompanyContext.Provider value={company}>
        <div className={classes.root}>
          <MenuBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
            setCompany={setCompany}
          />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </CompanyContext.Provider>
    </ApolloProvider>
  );
};
const routes: Partial<RouteObject>[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "user", element: <UserPage /> },
      { path: "company", element: <CompanyList /> },
      { path: "laborContract", element: <LaborContractPage /> },
      { path: "contractor", element: <ContractorPage /> },
      { path: "laborContract/:id", element: <LaborContractDetail /> },
      { path: "report", element: <ReportPage /> },
      { path: "employee", element: <EmployeePage /> },
      { path: "leave", element: <LeavePage /> },
      { path: "salary", element: <SalaryPage /> },
      { path: "finance", element: <FinancePage /> },
    ],
  },
  {
    path: "/front-stage",
    element: <App />,
    children: [{ element: <LaborContractSignPage />, path: ":uuid" }],
  },
];

export default routes;
